<template>
<a-layout-content>
    <div class="header_div">
        <a-input-search placeholder="Please enter the account/nickname/mailbox" style="width: 200px" @search="onSearch" class="align" />
        <a-button type="primary" icon="plus-circle" class="align btn_add" @click="showAdd">
            Add
        </a-button>
        <a-button icon="team" class="align btn_hei" @click="onShowRole">
            Role management
        </a-button>
    </div>
    <a-table rowKey="id" :columns="columns" :data-source="data" :loading="loading" :pagination="pagination">

        <span slot="nick_name" slot-scope="text">
            <b>{{ text }}</b>
        </span>

        <a-tag slot="account" slot-scope="text" color="#348ceb">
            {{ text }}
        </a-tag>

        <span slot="update_time" slot-scope="text">
            {{Dayjs(text).format('YYYY-MM-DD HH:mm:ss')}}
        </span>

        <span slot="create_time" slot-scope="text">
            {{Dayjs(text).format('YYYY-MM-DD HH:mm:ss')}}
        </span>

        <div slot="status" slot-scope="text,record">
            <a-switch default-checked v-if="text===0" @click="onSwitch($event,record.id)">
                <a-icon slot="checkedChildren" type="check" />
                <a-icon slot="unCheckedChildren" type="close" />
            </a-switch>
            <a-switch v-else @click="onSwitch($event,record.id)">
                <a-icon slot="checkedChildren" type="check" />
                <a-icon slot="unCheckedChildren" type="close" />
            </a-switch>
        </div>

        <span slot="action" slot-scope="text, record">
            <a-space size="small">
                <a-tooltip placement="left">
                    <template slot="title">
                        <span>delete</span>
                    </template>
                    <a-popconfirm title="Do you want to delete this user?" ok-text="yes" cancel-text="no" @confirm="del(record.id)">
                        <a-icon two-tone-color="#eb2f96" class="pointer" type="delete" />
                    </a-popconfirm>
                </a-tooltip>

                <span>|</span>

                <a-tooltip placement="top">
                    <template slot="title">
                        <span>edit</span>
                    </template>
                    <a-icon class="pointer" type="form" @click="showEdit(record.id, record.account, record.nick_name, record.email ,record.role_id)" />
                </a-tooltip>
            </a-space>
        </span>
    </a-table>

    <a-drawer title="Add new users" :width="400" :visible="visible_add" :body-style="{ paddingBottom: '80px' }" @close="onCloseAdd">


        <a-form :form="formAdd" layout="vertical" @submit="onAdd">
            <a-row :gutter="16">
                <a-col :span="24">
                    <a-form-item label="Username">
                        <a-input v-decorator="[
                  'account',
                  {
                    rules: [
                      { required: true, message: 'Please enter the user account' },
                      { min: 2, message: 'The account length should not be less than 2 digits' },
                      { max: 20, message: 'The account length cannot exceed 20 digits' },
                    ],
                  },
                ]" placeholder="Please enter the user account" />
                    </a-form-item>
                </a-col>

                <a-col :span="24">
                    <a-form-item label="Nickname">
                        <a-input v-decorator="[
                  'nick_name',
                  {
                    rules: [
                      { required: true, message: 'Please enter the user nickname' },
                      { min: 2, message: 'The length of the nickname should not be less than 2' },
                      { max: 20, message: 'The length of the nickname cannot exceed 20 bits' },
                    ],
                  },
                ]" placeholder="Please enter the user's nickname" />
                    </a-form-item>
                </a-col>

                <a-col :span="24">
                    <a-form-item label="E-Mail">
                        <a-input v-decorator="[
                  'email',
                  {
                    rules: [
                      { required: true, message: 'Please enter the user E-mail' },
                      { min: 6, message: 'The length of the mailbox must not be less than 6 digits' },
                      { max: 50, message: 'The length of the mailbox cannot exceed 50 bits' },
                    ],
                  },
                ]" placeholder="Please enter the user E-Mail" />
                    </a-form-item>
                </a-col>

                <a-col :span="24">
                    <a-form-item label="Role">
                        <a-select show-search :filter-option="onFilterOption" v-decorator="[
                  'role',
                  {
                    rules: [{ required: true, message: 'Please select the role' }],
                  },
                ]" placeholder="Please select the role">
                            <a-select-option v-for="(item, index) in role_list" :key="index" :value="item.id">{{ item.name }}</a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>

                <a-col :span="24">
                    <a-form-item label="password">
                        <a-input-password v-decorator="[
                  'passwd',
                  {
                    rules: [
                      { required: true, message: 'Please enter the user password' },
                      { min: 8, message: 'The password length must not be less than 8 bits' },
                      { max: 16, message: 'The password length cannot exceed 16 bits' },
                    ],
                  },
                ]" placeholder="Please enter the user password" type="password" />
                    </a-form-item>
                </a-col>

                <a-col :span="24">
                    <a-form-item label="Confirm Password">
                        <a-input-password v-decorator="[
                  'repasswd',
                  {
                    rules: [
                      { required: true, message: 'Please confirm the user password' },
                      { min: 8, message: 'The password length must not be less than 8 bits' },
                      { max: 16, message: 'The password length cannot exceed 16 bits' },
                    ],
                  },
                ]" placeholder="Please confirm the user password" type="password" />
                    </a-form-item>
                </a-col>
            </a-row>

            <div :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }">
                <a-button :style="{ marginRight: '8px' }" @click="onCloseAdd">close</a-button>
                <a-button type="primary" html-type="submit" :loading="loadingAdd">
                    Add
                </a-button>
            </div>
        </a-form>
    </a-drawer>

    <a-drawer title="Update user information" :width="400" :visible="visible_edit" :body-style="{ paddingBottom: '80px' }" @close="onCloseEdit">


        <a-form :form="formUpdate" layout="vertical" @submit="onUpdate">
            <a-row :gutter="16">
                <a-col :span="24">
                    <a-form-item label="Account">
                        <a-input v-decorator="[
                  'account',
                  {
                    rules: [{ required: true, message: 'Please enter the user account' }],
                  },
                ]" placeholder="Please enter the user account" :disabled="true" />
                    </a-form-item>
                </a-col>

                <a-col :span="24">
                    <a-form-item label="Nick name">
                        <a-input v-decorator="[
                  'nick_name',
                  {
                    rules: [
                      { required: true, message: 'Please enter the user nickname' },
                      { min: 2, message: 'The length of the nickname should not be less than 2' },
                      { max: 20, message: 'The length of the nickname cannot exceed 20 bits' },
                    ],
                  },
                ]" placeholder="Please enter the user's nickname" />
                    </a-form-item>
                </a-col>

                <a-col :span="24">
                    <a-form-item label="E-Mail">
                        <a-input v-decorator="[
                  'email',
                  {
                    rules: [
                      { required: true, message: 'Please enter the user E-mail' },
                      { min: 6, message: 'The length of the mailbox must not be less than 6 digits' },
                      { max: 50, message: 'The length of the mailbox cannot exceed 50 bits' },
                    ],
                  },
                ]" placeholder="Please enter the user E-Mail" />
                    </a-form-item>
                </a-col>

                <a-col :span="24">
                    <a-form-item label="Role">
                        <a-select show-search :filter-option="onFilterOption" v-decorator="[
                  'role',
                  {
                    rules: [{ required: true, message: 'Please select the role' }],
                  },
                ]" placeholder="Please select the role">
                            <a-select-option v-for="(item, index) in role_list" :key="index" :value="item.id">{{ item.name }}</a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>

                <a-col :span="24">
                    <a-form-item label="password">
                        <a-input-password v-decorator="[
                        'passwd',{
                        rules: [
                            { min: 8, message: 'The password length must not be less than 8 bits' },
                            { max: 16, message: 'The password length cannot exceed 16 bits' },
                        ]}]" placeholder="Please enter the user password" type="password" />
                    </a-form-item>
                </a-col>

                <a-col :span="24">
                    <a-form-item label="Confirm Password">
                        <a-input-password v-decorator="[
                        'repasswd',{
                        rules: [
                            { min: 8, message: 'The password length must not be less than 8 bits' },
                            { max: 16, message: 'The password length cannot exceed 16 bits' },
                        ]}]" placeholder="Please confirm the user password" type="password" />
                    </a-form-item>
                </a-col>

                <a-col :span="24">
                    <p class="center">Tip: The password is not filled in, and the password is not modified by default</p>
                </a-col>
            </a-row>

            <div :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }">
                <a-button :style="{ marginRight: '8px' }" @click="onCloseEdit">close</a-button>
                <a-button type="primary" html-type="submit" :loading="loadingUpdate">
                    renew
                </a-button>
            </div>
        </a-form>
    </a-drawer>

    <a-drawer title="Corner list" :width="300" :visible="visible_role" :body-style="{ paddingBottom: '80px' }" @close="onCloseRole">
        <a-button icon="plus-circle" type="primary" style="width: 100%;" @click="onShowRoleEdit(0,'','')">
            Create a new role
        </a-button>

        <div v-for="(item,index) in role_list" :key="index" style="
            height: 50px;
            border-bottom: 1px solid #e9e9e9;
            line-height: 50px;
        ">
            <div style="float: left;">{{item.name}}</div>
            <div style="float: right;">
                <a-popconfirm title="Do you want to delete this role?" ok-text="yes" cancel-text="no" @confirm="onDelRole(item.id)">
                    <a-icon type="delete" class="pointer" />
                </a-popconfirm>
                <a-icon @click="onShowRoleEdit(item.id,item.name,item.remarks)" type="edit" class="pointer" />
            </div>
        </div>

        <a-drawer title="Managed role" :width="600" :closable="false" :visible="visible_role_edit" @close="onCloseRoleEdit">
            <div>
                <span style="color: #e93636;margin-right: 5px;vertical-align: middle;">*</span>Role Name:
            </div>

            <a-input v-model="role_name" placeholder="Please enter the character name" style="margin-top: 10px;margin-bottom: 20px;">
                <a-icon slot="prefix" type="team" />
            </a-input>

            <a-transfer show-search :filterOption="filterOption" :listStyle="{
                    width: '248px',
                    height: '400px',
                }" :titles="['Not owned', 'Already']" :data-source="nav_list" :targetKeys="nav_key" :render="item => `${item.name}`" @change="onChangeNav" />

            <div style="margin-top: 20px;">
                Remark:
            </div>
            <a-textarea style="margin-top: 10px;" v-model="role_remarks" placeholder="Please enter the character's note" :auto-size="{ minRows: 3, maxRows: 5 }" />

            <a-button v-if="role_id===0" type="primary" style="width: 100%;margin-top: 20px;" @click="onUpdateRole">
                Add a role
            </a-button>
            <a-button v-else type="primary" style="width: 100%;margin-top: 20px;" @click="onUpdateRole">
                Update role
            </a-button>
        </a-drawer>
        <div :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }">
            <a-button :style="{ marginRight: '8px' }" @click="onCloseRole">close</a-button>
        </div>
    </a-drawer>
</a-layout-content>
</template>

<script>

const columns = [
    {
        title: "Display Name",
        dataIndex: "nick_name",
        key: "nick_name",
        scopedSlots: {
            customRender: "nick_name",
        },
    },
    {
        title: "Username",
        dataIndex: "account",
        key: "account",
        scopedSlots: {
            customRender: "account",
        },
    },
    {
        title: "E-Mail",
        dataIndex: "email",
        key: "email",
    },
    {
        title: "Role",
        dataIndex: "role_name",
        key: "role_name",
    },
    {
        title: "Update time",
        key: "update_time",
        dataIndex: "update_time",
        scopedSlots: {
            customRender: "update_time",
        },
    },
    {
        title: "Creation time",
        key: "create_time",
        dataIndex: "create_time",
        scopedSlots: {
            customRender: "create_time",
        },
    },
    {
        title: "state",
        key: "status",
        dataIndex: "status",
        scopedSlots: {
            customRender: "status",
        },
    },
    {
        title: "Action",
        key: "action",
        scopedSlots: {
            customRender: "action",
        },
        width: 60,
    },
];

export default {
    name: "userHome",
    data() {
        return {
            disabled: false,
            columns,
            loading: false,
            so_text: "",
            data: [],
            formAdd: this.$form.createForm(this),
            visible_add: false,
            formUpdate: this.$form.createForm(this),
            visible_edit: false,
            key: 0,
            loadingUpdate: false,
            loadingAdd: false,
            pagination: {
                total: 0,
                defaultPageSize: 10,
                showTotal: total => `common ${total} Article data`,
                showSizeChanger: true,
                pageSizeOptions: ['10', '15', '20', '50', '100'],
                onShowSizeChange: this.onPageShowSizeChange,
                onChange: this.onPageChange
            },
            visible_role: false,
            visible_role_edit: false,
            role_list: [],
            nav_list: [],
            nav_key: [],
            role_name: "",
            role_remarks: "",
            role_id: 0,
            curr_page: 1,
            curr_page_size: 10
        };
    },
    mounted() {
        this.onLoad();
    },
    methods: {
        filterOption(inputValue, option) {
            return option.description.indexOf(inputValue) > -1;
        },
        onChangeNav(nextTargetKeys, direction, moveKeys) {
            this.nav_key = nextTargetKeys;
        },
        onPageShowSizeChange(current, pageSize) {
            this.curr_page = current;
            this.curr_page_size = pageSize;
            this.onLoad(this.so_text, this.select_type, current, pageSize);
        },
        onPageChange(page, pageSize) {
            this.curr_page = page;
            this.curr_page_size = pageSize;
            this.onLoad(this.so_text, this.select_type, page, pageSize);
        },
        onLoad(keywords = "", page = 1, page_count = 10) {
            this.loading = true;
            this.$http
                .post("/api/v1/rest/get/user/list", {
                    keywords: keywords,
                    page: page,
                    page_count: page_count
                })
                .then((res) => {
                    if (res.code == 0) {
                        this.data = res.data.list;
                        this.pagination.total = res.data.total_count;
                        this.loading = false;
                    } else {
                        this.$message.error(res.msg);
                        this.loading = false;
                    }
                });
        },
        del(id) {
            this.$http
                .post("/api/v1/rest/post/user/del", {
                    id: id,
                })
                .then((res) => {
                    if (res.code == 0) {
                        this.$message.success("successfully deleted");
                        this.onLoad(this.so_text, this.select_type, this.curr_page, this.curr_page_size);
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        onAdd(e) {
            e.preventDefault();

            this.formAdd.validateFields((err, values) => {
                if (!err) {
                    this.loadingAdd = true;

                    if (values.passwd !== values.repasswd) {
                        this.$message.warning("Two passwords are the same");
                        this.loadingAdd = false;
                        return false;
                    }

                    this.$http
                        .post("/api/v1/rest/post/user/add", {
                            account: values.account,
                            passwd: values.passwd,
                            nick_name: values.nick_name,
                            email: values.email,
                            role_id: values.role
                        })
                        .then((res) => {
                            if (res.code == 0) {
                                this.$message.success("Added successfully");
                                this.loadingAdd = false;
                                this.onCloseAdd();
                                this.onLoad();
                            } else {
                                this.$message.error(res.msg);
                                this.loadingAdd = false;
                            }
                        });
                }
            });
        },
        onUpdate(e) {
            e.preventDefault();

            this.formUpdate.validateFields((err, values) => {
                if (!err) {
                    this.loadingUpdate = true;

                    var passwd = "";

                    if (typeof values.passwd == "undefined") {
                        values.passwd = ""
                    }

                    if (typeof values.repasswd == "undefined") {
                        values.repasswd = ""
                    }

                    if (values.passwd !== values.repasswd) {
                        this.$message.warning("The passwords are different");
                        this.loadingUpdate = false;
                        return false;
                    } else {
                        passwd = values.passwd;
                    }

                    this.$http
                        .post("/api/v1/rest/post/user/update", {
                            id: this.key,
                            nick_name: values.nick_name,
                            email: values.email,
                            passwd: passwd,
                            role_id: values.role
                        })
                        .then((res) => {
                            if (res.code == 0) {
                                this.$message.success("update completed");
                                this.loadingUpdate = false;
                                this.onCloseEdit();
                                this.onLoad(this.so_text, this.select_type, this.curr_page, this.curr_page_size);
                            } else {
                                this.$message.error(res.msg);
                                this.loadingUpdate = false;
                            }
                        });
                }
            });
        },
        showEdit(key, account, nick_name, email, role_id) {
            this.onLoadRoleList();

            setTimeout(() => {
                this.formUpdate.setFieldsValue({
                    account: account,
                    nick_name: nick_name,
                    email: email,
                    role: role_id
                });
            }, 100);

            this.key = key;

            this.visible_edit = true;
        },
        onCloseEdit() {
            this.visible_edit = false;
        },
        showAdd() {
            this.onLoadRoleList();

            setTimeout(() => {
                this.formAdd.setFieldsValue({
                    account: "",
                    nick_name: "",
                    email: "",
                });
            }, 100);

            this.visible_add = true;
        },
        onCloseAdd() {
            this.visible_add = false;
        },
        onLoadRoleList() {
            this.$http
                .post("/api/v1/rest/get/role/list")
                .then((res) => {
                    if (res.code == 0) {
                        this.role_list = res.data;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        onShowRole() {
            this.onLoadRoleList();
            this.visible_role = true;
        },
        onCloseRole() {
            this.visible_role = false;
        },
        onCloseRoleEdit() {
            this.visible_role_edit = false;
        },
        onShowRoleEdit(id, name, remarks) {
            this.role_id = id;
            this.role_name = "";
            this.role_remarks = "";
            this.nav_key = []

            this.$http
                .post("/api/v1/rest/get/nav/list")
                .then((res) => {
                    if (res.code == 0) {
                        this.nav_list = res.data;
                        this.visible_role_edit = true;
                    } else {
                        this.$message.error(res.msg);
                    }
                });

            if (id != 0) {
                this.role_name = name;
                this.role_remarks = remarks;

                this.$http
                    .post("/api/v1/rest/get/role_nav/list", {
                        role_id: id,
                    })
                    .then((res) => {
                        if (res.code == 0) {
                            this.nav_key = res.data;
                            this.visible_role_edit = true;
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
            }
        },
        onUpdateRole() {
            this.$http
                .post("/api/v1/rest/post/roles/add", {
                    id: this.role_id,
                    name: this.role_name,
                    nav_key: this.nav_key,
                    remarks: this.role_remarks,
                })
                .then((res) => {
                    if (res.code == 0) {
                        this.$message.success("Success");
                        this.onCloseRoleEdit();
                        this.onShowRole();
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        onDelRole(id) {
            this.$http
                .post("/api/v1/rest/post/roles/del", {
                    id: id,
                })
                .then((res) => {
                    if (res.code == 0) {
                        this.$message.success("Delete the role success");
                        this.onShowRole();
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        onSearch(value) {
            this.so_text = value;
            this.onLoad(this.so_text);
        },
        onFilterOption(input, option) {
            return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
        },
        onSwitch(bool, id) {
            var status = 1;
            if (bool) {
                status = 0
            }

            this.$http
                .post("/api/v1/rest/post/user/status", {
                    id: id,
                    status: status
                })
                .then((res) => {
                    if (res.code == 0) {
                        this.$message.success("Success");
                        this.onLoad(this.so_text, this.select_type, this.curr_page, this.curr_page_size);
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        }
    },
};
</script>

<style lang="less" scoped>
@import url("../../theme/style.less");

.pointer {
    cursor: pointer;
}

.center {
    text-align: center;
}

.bright {
    .header_div {
        width: 100%;
        background: #ffffff;
        margin-bottom: 10px;
        padding: 10px;
        border-radius: 8px;

        .align {
            vertical-align: middle;
        }

        .btn_add {
            float: right;
        }
    }

    .ant-table-wrapper {
        background: #fff;
        border-radius: 8px;
        padding: 5px 10px;

        /deep/ .ant-table-thead>tr>th {
            background: #fff;
        }
    }
}

.dark {
    .header_div {
        width: 100%;
        background: #202020;
        margin-bottom: 10px;
        padding: 10px;
        border-radius: 8px;

        .align {
            vertical-align: middle;
        }

        .btn_add {
            float: right;
        }
    }

    .ant-table-wrapper {
        background: #202020;
        border-radius: 8px;
        padding: 5px 10px;

        /deep/ .ant-table-thead>tr>th {
            background: #202020;
            border-bottom: 1px solid #303030;
        }
    }
}


</style>
